.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
	display: none !important;
}
.ant-picker-decade-panel, 
.ant-picker-year-panel, 
.ant-picker-quarter-panel, 
.ant-picker-month-panel, 
.ant-picker-week-panel, 
.ant-picker-date-panel, 
.ant-picker-time-panel {
    width: 415px !important;
}
.ant-picker-header {
    padding: 10px 40px !important;
    border-bottom: none !important;
}
.ant-picker-header-super-prev-btn {
    display: none !important;
}
.ant-picker-header-super-next-btn {
    display: none !important;
}
.ant-picker-month-panel .ant-picker-header-super-prev-btn {
    display: block !important;
}
.ant-picker-month-panel .ant-picker-header-super-next-btn {
    display: block !important;
}
.ant-picker-year-btn,
.ant-picker-month-btn {
    font-size: 16px !important;
}
.ant-picker-date-panel .ant-picker-content th {
    font-weight: 500 !important;
    color: #74738a !important;
}
.ant-picker-month-panel .ant-picker-content td {
	height: 20px !important;
}
.ant-picker-cell .ant-picker-cell-inner {
    min-width: 32px !important;
    height: 32px !important;
    line-height: 32px !important;
    font-weight: 500;
}
.ant-picker-prev-icon, 
.ant-picker-next-icon, 
.ant-picker-super-prev-icon, 
.ant-picker-super-next-icon {
    width: 10px !important;
    height: 10px !important;
}
.ant-picker-prev-icon:before, 
.ant-picker-next-icon:before, 
.ant-picker-super-prev-icon:before, 
.ant-picker-super-next-icon:before {
    width: 10px !important;
    height: 10px !important;
}
.ant-picker-super-prev-icon:after, 
.ant-picker-super-next-icon:after {
    width: 10px !important;
    height: 10px !important;
}
.ant-picker-date-panel .ant-picker-body,
.ant-picker-week-panel .ant-picker-body{
    padding: 0 54px 30px 54px !important;
}
.ant-picker-month-panel .ant-picker-body {
    padding: 0 50px 30px 50px !important;
}
.ant-picker-date-panel .ant-picker-content,
.ant-picker-week-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
    width: 100% !important;
}
.ant-picker-content th {
    font-size: 14px !important;
    color: #74738a !important;
}
.ant-picker-week-panel-row-selected td, 
.ant-picker-week-panel-row-selected:hover td {
	background-color: #6f55ff !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected > .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-start > .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end > .ant-picker-cell-inner {
    background-color: #6f55ff !important;
    border-radius: 24px !important;
    font-weight: 500;
	color: #fff !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover:before {
    background-color: rgba(111,85,255,0.2) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before {
    background-color: rgba(111,85,255,0.2) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):before {
    background-color: rgba(111,85,255,0.2) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):before {
    background-color: rgba(111,85,255,0.2) !important;
}
.ant-picker-month-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before {
    background-color: rgba(111,85,255,0.2) !important;
}
.ant-picker-month-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before {
    background-color: rgba(111,85,255,0.2) !important;
}
.ant-picker-cell-range-hover:after {
    display: none !important;
}
.ant-picker-cell-range-hover-start:after {
    display: none !important;
}
.ant-picker-cell-range-hover-end:after {
    display: none !important;
}
.ant-picker-cell-in-view {
    color: #74738a !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range:before  {
    background-color: rgba(111,85,255,0.1) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range > .ant-picker-cell-inner {
    color: #74738a !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: unset !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before, 
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before {
	background-color: rgba(111,85,255,0.1) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover-start:not(.ant-picker-cell-range-start-single):before, 
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover-end:not(.ant-picker-cell-range-end-single):before {
	background-color: rgba(111,85,255,0.2) !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:not(.ant-picker-cell-range-start-single):before, 
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:not(.ant-picker-cell-range-end-single):before {
	background-color: rgba(111,85,255,0.2) !important;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after, 
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
	background-color: rgba(111,85,255,0.1) !important;
}

.ant-picker-content th {
    padding-bottom: 5px !important;
}
.ant-picker-content td {
    padding: 6px !important;
}
.ant-picker-cell:before {
    height: 32px !important;
}
.ant-picker-year-panel .ant-picker-cell-inner, 
.ant-picker-quarter-panel .ant-picker-cell-inner, 
.ant-picker-month-panel .ant-picker-cell-inner {
	width: 70px !important;
}
.ant-picker-cell-disabled:before {
	background: none !important;
} 
.ant-picker-month-panel .ant-picker-content {
	height: auto !important;
}

/* 달력 월 하나로 나오는 css */
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
}

.ant-picker-panels > *:last-child {
    display: none !important;
}

.ant-picker-panel-container, .ant-picker-footer {
    width: 415px !important;
    border-radius: 8px !important;
}

.ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
}


/* =========================================================
 * mobile
 * ========================================================= */
@media only screen and (hover: none) and (pointer: coarse) {
	.ant-picker-dropdown-range {
		top: auto !important;
		height: 100vh !important;
		background-color: rgba(0, 0, 0, 0.4) !important;
	}
	.ant-picker-range-wrapper {
		height: 100vh !important;
		align-items: flex-end !important;
	}
	.ant-picker-decade-panel, 
	.ant-picker-year-panel, 
	.ant-picker-quarter-panel, 
	.ant-picker-month-panel, 
	.ant-picker-week-panel, 
	.ant-picker-date-panel, 
	.ant-picker-time-panel {
		width: 100% !important;
	}
	.ant-picker-panel-container, .ant-picker-footer {
		border-radius: 8px 8px 0 0 !important;
	}
	.ant-picker-header {
		padding: 10px 20px !important;
	}
	.ant-picker-date-panel .ant-picker-body, 
	.ant-picker-week-panel .ant-picker-body {
		padding: 0 20px 30px 20px !important;
	}
	.ant-picker-month-panel .ant-picker-body {
		padding: 0 30px 30px 30px !important;
	}
	.ant-picker-panel-container, .ant-picker-footer {
		width: 100% !important;
	}
}