.ant-select-selector {
    border: 1px solid #d9d9d9 !important;
    box-shadow: none !important;
    align-items: center !important;
    height: inherit !important;
    border-radius: 8px !important;
}
.ant-select-selection-item {
    text-align: left;
    font-family: 'Spoqa Han Sans Neo';
    padding-top: 3px !important;
    padding-left: 8px !important;
}
.ant-table-cell {
    text-align: center !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    font-size: 16px;
    font-weight: 500 !important;
    background-color: #fff !important;
    color: #6f55ff !important;
}

.ant-select-item {
    font-size: 16px !important;
    font-weight: 500 !important;
}
.ant-table-thead > tr > th {
    background-color: #fff !important;
}

.ant-table-row.ant-table-row-level-0:nth-child(2n-1) {
    background-color: rgb(251, 250, 255) !important;
}
@media only screen and (hover: none) and (pointer: coarse) {
    .ant-select-dropdown {
        padding: unset !important;
        width: 100% !important;
        max-width: unset !important;
        left: unset !important;
        height: 100vh !important;
        background-color: rgba(0, 0, 0, 0.4) !important;
    }
    .rc-virtual-list {
        border-radius: 12px 12px 0px 0px;
        padding: 16px !important;
        position: fixed !important;
        bottom: 0px;
        width: 100%;
        background-color: #fff;
        height: calc(100vh - 400px);
        transition: linear 0s ;
        font-family: 'Spoqa Han Sans Neo';
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.5px;
    }
    .rc-virtual-list-holder {
        max-height: 340px !important;
        overflow-y: auto;
    }
    .rc-virtual-list-holder-inner > div {
        margin-bottom: 28px;
    }
    .rc-virtual-list-scrollbar {
        display: none !important;
    }
}
